import axios from "axios";
import Cookies from "universal-cookie";

import { affiliateAPI } from "../api/affiliateAPI";
import { ratesProductAPI } from "../api/ratesProductAPI";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";
import {
    ActionChargingRateTypes,
    AirShippingRatesObjectType,
    ChargingRatesFileType,
    ChargingRatesType,
    FclcbmTruckRateObjectType,
    SetRatesProductType,
    SetTenantPersonalRatesType,
    TRatesTenantList,
    TGetTenantPersonalRates,
    TManagementRatesObject,
    TPatchPersonalRatesData,
    TPatchTenantShippingCreditsData,
    TPatchManagementRates,
    TSetRatesManagement,
    TPatchTenantPaymentsTermsData,
    TPatchUpdateTenantPersonalTiersReqData,
    TRatesLoadingStates,
    TSetRatesLoadingStates,
    TSetRatesTenantsList,
    TSetTenantPersonalTiers,
    TTenantPersonalTiers,
    ThunkType,
    Truck16cbmRateObjectType,
    Truck2k8kgRateObjectType,
    TruckCbmRateObjectType,
    TruckKgRateObjectType,
    TUKTruckCbmRates,
    TUKTruckKgRates,
} from "./types/ChargingRatesTypes/ChargingRates.types";
import { UUID } from "crypto";

export const SET_RATES_PRODUCT = "SET_RATES_PRODUCT";
export const SET_RATES_TENANTS_LIST = "SET_RATES_TENANTS_LIST";
export const SET_TENANT_PERSONAL_RATES = "SET_TENANT_PERSONAL_RATES";
export const SET_RATES_MANAGEMENT = "SET_RATES_MANAGEMENT";
export const SET_TENANT_PERSONAL_TIERS = "SET_TENANT_PERSONAL_TIERS";
export const SET_RATES_LOADING_STATES = "SET_RATES_LOADING_STATES";

export type InitialStateType = {
    ratesManagement: TManagementRatesObject | null;
    ratesProduct: null | ChargingRatesType;
    tenantsList: TRatesTenantList[] | null;
    tenantPersonalRates: TGetTenantPersonalRates | null;
    tenantPersonalTiers: TTenantPersonalTiers | null;
    loadingStates: TRatesLoadingStates;
};

let initialState: InitialStateType = {
    ratesProduct: null,
    tenantsList: null,
    tenantPersonalRates: null,
    ratesManagement: null,
    tenantPersonalTiers: null,
    loadingStates: {
        isLoadingMainRates: false,
        isLoadingCustomerRates: false,
        isLoadingCustomerTiers: false,
        isLoadingConsolidationManagement: false,
    },
};

const ratesProductReducer = (state = initialState, action: ActionChargingRateTypes): InitialStateType => {
    switch (action.type) {
        case SET_RATES_PRODUCT: {
            return {
                ...state,
                ratesProduct: action.data,
            };
        }
        case SET_RATES_TENANTS_LIST: {
            return {
                ...state,
                tenantsList: action.data,
            };
        }
        case SET_TENANT_PERSONAL_RATES: {
            return {
                ...state,
                tenantPersonalRates: action.data,
            };
        }
        case SET_RATES_MANAGEMENT: {
            return {
                ...state,
                ratesManagement: action.data,
            };
        }
        case SET_TENANT_PERSONAL_TIERS: {
            return {
                ...state,
                tenantPersonalTiers: action.data,
            };
        }
        case SET_RATES_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};

export const SetRatesProduct = (data: ChargingRatesType): SetRatesProductType => ({
    type: SET_RATES_PRODUCT,
    data,
});

export const SetRatesTenantsList = (data: TRatesTenantList[]): TSetRatesTenantsList => ({
    type: SET_RATES_TENANTS_LIST,
    data,
});

export const SetTenantPersonalRates = (data: TGetTenantPersonalRates | null): SetTenantPersonalRatesType => ({
    type: SET_TENANT_PERSONAL_RATES,
    data,
});

export const SetRatesManagement = (data: TManagementRatesObject): TSetRatesManagement => ({
    type: SET_RATES_MANAGEMENT,
    data,
});

export const SetTenantPersonalTiers = (data: TTenantPersonalTiers | null): TSetTenantPersonalTiers => ({
    type: SET_TENANT_PERSONAL_TIERS,
    data,
});

export const SetRatesLoadingStates = (data: { [key in keyof TRatesLoadingStates]?: boolean }): TSetRatesLoadingStates => ({
    type: SET_RATES_LOADING_STATES,
    data,
});

export const getRatesProduct = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingMainRates: true }));

            const response = await ratesProductAPI.getRatesProduct(cookies);
            dispatch(SetRatesProduct(response.data));

            dispatch(SetRatesLoadingStates({ isLoadingMainRates: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postRatesProduct = (
    cookies: Cookies,
    {
        fast_boat_12_99_kg_east,
        fast_boat_12_99_kg_mid,
        fast_boat_12_99_kg_west,
        fast_boat_gt_100_kg_east,
        fast_boat_gt_100_kg_mid,
        fast_boat_gt_100_kg_west,
        meidum_boat_12_99_kg_east,
        meidum_boat_12_99_kg_mid,
        meidum_boat_12_99_kg_west,
        meidum_boat_gt_100_kg_east,
        meidum_boat_gt_100_kg_mid,
        meidum_boat_gt_100_kg_west,
        slow_boat_12_99_kg_east,
        slow_boat_12_99_kg_mid,
        slow_boat_12_99_kg_west,
        slow_boat_gt_100_kg_east,
        slow_boat_gt_100_kg_mid,
        slow_boat_gt_100_kg_west,
        over_266_cm_rate,
        own_ff_prep_rate_over_50_cbm,
        remote_warehouse_surcharge,
        prep_rate,
        category_surcharge_rmb,
        rmb_conversation_rate,
        skudrop_margin,
        carton_range_22_40_kg,
        length_width_height_75_120_cm,
        truck_weight_gte_30,
        truck_kg_longest_size_120_150,
        truck_kg_longest_size_150_200,
        truck_cbm_longest_size_120_150,
        truck_cbm_longest_size_150_200,
        insurance_percent,
        fast_boat_cbm_east,
        fast_boat_cbm_mid,
        fast_boat_cbm_west,
        fast_boat_kg_ups_east,
        fast_boat_kg_ups_mid,
        fast_boat_kg_ups_west,
        meidum_boat_cbm_east,
        meidum_boat_cbm_mid,
        meidum_boat_cbm_west,
        meidum_boat_kg_ups_east,
        meidum_boat_kg_ups_mid,
        meidum_boat_kg_ups_west,
        slow_boat_cbm_east,
        slow_boat_cbm_mid,
        slow_boat_cbm_west,
        slow_boat_kg_ups_east,
        slow_boat_kg_ups_mid,
        slow_boat_kg_ups_west,
        min_cbm_value,
        min_kg_value,
        uk_additional_fee_for_weight,
        uk_kg_min_weight,
        uk_moq_min_weight,
        uk_mid_size_gte_70_extra,
        uk_size_gte_100_extra,
        uk_sizes_formula_extra,
        storage_fees_per_day_per_cbm,
        prep_rate_71_140_cbm,
        skudrop_margin_71_140_cbm,
        storage_fees_per_day_per_cbm_71_140_cbm,
    }: { [key: string]: string },
    fileName: null | ChargingRatesFileType,
    truck_cbm_rate: TruckCbmRateObjectType,
    truck_kg_rate: TruckKgRateObjectType,
    air_shipping_rates: AirShippingRatesObjectType,
    fclcbm_truck_rate: FclcbmTruckRateObjectType,
    truck_2k8kg_rate: Truck2k8kgRateObjectType,
    truck_16cbm_rate: Truck16cbmRateObjectType,
    ratesFile: null | ChargingRatesFileType,
    uk_kg_sea: { [key: string]: string | number | null },
    uk_kg_truck: { [key: string]: string | number | null },
    uk_cbm_truck_sea: TUKTruckCbmRates,
    uk_kg_truck_sea: TUKTruckKgRates
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingMainRates: true }));
            let formData = new FormData();
            if (fileName || ratesFile) {
                fileName && formData.append("remote_wareouse_file", fileName.file);
                ratesFile && formData.append("rates_file", ratesFile.file);
                await ratesProductAPI.postRatesProductFile(cookies, formData);
            }
            let data = {
                fast_boat_12_99_kg_east,
                fast_boat_12_99_kg_mid,
                fast_boat_12_99_kg_west,
                fast_boat_gt_100_kg_east,
                fast_boat_gt_100_kg_mid,
                fast_boat_gt_100_kg_west,
                meidum_boat_12_99_kg_east,
                meidum_boat_12_99_kg_mid,
                meidum_boat_12_99_kg_west,
                meidum_boat_gt_100_kg_east,
                meidum_boat_gt_100_kg_mid,
                meidum_boat_gt_100_kg_west,
                slow_boat_12_99_kg_east,
                slow_boat_12_99_kg_mid,
                slow_boat_12_99_kg_west,
                slow_boat_gt_100_kg_east,
                slow_boat_gt_100_kg_mid,
                slow_boat_gt_100_kg_west,
                over_266_cm_rate,
                remote_warehouse_surcharge,
                prep_rate,
                category_surcharge_rmb,
                rmb_conversation_rate,
                skudrop_margin,
                own_ff_prep_rate_over_50_cbm,
                carton_range_22_40_kg,
                length_width_height_75_120_cm,
                truck_weight_gte_30,
                truck_kg_longest_size_120_150,
                truck_kg_longest_size_150_200,
                truck_cbm_longest_size_120_150,
                truck_cbm_longest_size_150_200,
                insurance_percent,
                fast_boat_cbm_east,
                fast_boat_cbm_mid,
                fast_boat_cbm_west,
                fast_boat_kg_ups_east,
                fast_boat_kg_ups_mid,
                fast_boat_kg_ups_west,
                meidum_boat_cbm_east,
                meidum_boat_cbm_mid,
                meidum_boat_cbm_west,
                meidum_boat_kg_ups_east,
                meidum_boat_kg_ups_mid,
                meidum_boat_kg_ups_west,
                slow_boat_cbm_east,
                slow_boat_cbm_mid,
                slow_boat_cbm_west,
                slow_boat_kg_ups_east,
                slow_boat_kg_ups_mid,
                slow_boat_kg_ups_west,
                min_cbm_value,
                min_kg_value,
                uk_additional_fee_for_weight,
                uk_kg_min_weight,
                uk_moq_min_weight,
                uk_mid_size_gte_70_extra,
                uk_size_gte_100_extra,
                uk_sizes_formula_extra,
                uk_kg_sea,
                uk_kg_truck,
                truck_cbm_rate: Object.keys(truck_cbm_rate).length > 0 ? truck_cbm_rate : null,
                truck_kg_rate: Object.keys(truck_cbm_rate).length > 0 ? truck_kg_rate : null,
                air_shipping_rates: Object.keys(air_shipping_rates).length > 0 ? air_shipping_rates : null,
                fclcbm_truck_rate: Object.keys(fclcbm_truck_rate).length > 0 ? fclcbm_truck_rate : null,
                truck_2k8kg_rate: Object.keys(truck_2k8kg_rate).length > 0 ? truck_2k8kg_rate : null,
                truck_16cbm_rate: Object.keys(truck_16cbm_rate).length > 0 ? truck_16cbm_rate : null,
                storage_fees_per_day_per_cbm,
                prep_rate_71_140_cbm,
                skudrop_margin_71_140_cbm,
                storage_fees_per_day_per_cbm_71_140_cbm,
                uk_cbm_truck_sea,
                uk_kg_truck_sea,
            };

            !ratesFile && (await ratesProductAPI.postRatesProduct(cookies, data));
            dispatch(getRatesProduct(cookies));
            displaySuccess("Rates applied successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetRatesLoadingStates({ isLoadingMainRates: false }));
                displayError(e.response.data.message, "An error occurred while applying rates");
            }
        }
    };
};

// Customer rates

export const getRatesTenantsList = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: true, isLoadingCustomerTiers: true }));

            const response = await ratesProductAPI.getTenantsList(cookies);
            dispatch(SetRatesTenantsList(response.data));

            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: false, isLoadingCustomerTiers: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTenantPersonalRates = (cookies: Cookies, tenantId: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: true }));

            const response = await ratesProductAPI.getTenantPersonalRates(cookies, tenantId);
            dispatch(SetTenantPersonalRates(response.data));

            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchTenantPersonalRates = (cookies: Cookies, tenantId: string, ratesId: string, data: TPatchPersonalRatesData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: true }));

            await ratesProductAPI.patchTenantPersonalRates(cookies, ratesId, data);

            dispatch(getTenantPersonalRates(cookies, tenantId));

            displaySuccess("Changes applied successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: false }));
                displayError(e.response.data, "An error occurred while applying rates");
            }
        }
    };
};

export const patchTenantShippingCredits = (cookies: Cookies, tenantId: string, data: TPatchTenantShippingCreditsData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: true }));

            await affiliateAPI.patchTenantShippingCredits(cookies, tenantId, data);

            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: false }));
            displaySuccess("Shipping credits added successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: false }));
                displayError(e.response.data, "An error occurred while applying rates");
            }
        }
    };
};

// Customer tiers

export const getTenantPersonalTiers = (cookies: Cookies, tenantId: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerTiers: true }));

            const response = await ratesProductAPI.getTenantPersonalTiers(cookies, tenantId);
            dispatch(SetTenantPersonalTiers(response.data));

            dispatch(SetRatesLoadingStates({ isLoadingCustomerTiers: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchUpdateTenantPersonalTiers = (
    cookies: Cookies,
    tenantId: UUID,
    ratesId: UUID,
    isChangedTierType: boolean,
    data: TPatchUpdateTenantPersonalTiersReqData
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerTiers: true }));

            await ratesProductAPI.patchUpdateTenantPersonalTiers(cookies, ratesId, data);

            dispatch(getTenantPersonalTiers(cookies, tenantId));
            if (isChangedTierType) {
                dispatch(getRatesTenantsList(cookies));
            }

            displaySuccess("Tiers applied successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetRatesLoadingStates({ isLoadingCustomerTiers: false }));
                displayError(e.response.data, "An error occurred while applying rates");
            }
        }
    };
};

export const patchTenantPaymentTerms = (cookies: Cookies, tenantId: string, tenantIndex: string, data: TPatchTenantPaymentsTermsData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: true }));
            await ratesProductAPI.patchTenantPaymentsTerms(cookies, tenantId, data);
            const getRatesResponse = await ratesProductAPI.getTenantPersonalRates(cookies, tenantIndex);
            dispatch(SetTenantPersonalRates(getRatesResponse.data));
            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: false }));
        } catch (e) {
            dispatch(SetRatesLoadingStates({ isLoadingCustomerRates: false }));
            displayError("An error occurred while updating tenant");
        }
    };
};

export const getRatesManagement = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingConsolidationManagement: true }));

            const response = await ratesProductAPI.getRatesManagement(cookies);
            dispatch(SetRatesManagement(response.data));

            dispatch(SetRatesLoadingStates({ isLoadingConsolidationManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postRatesManagement = (cookies: Cookies, id: string, data: TPatchManagementRates): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetRatesLoadingStates({ isLoadingConsolidationManagement: true }));

            const response = await ratesProductAPI.patchRatesManagement(cookies, id, data);
            dispatch(SetRatesManagement({ ...response.data, id }));

            dispatch(SetRatesLoadingStates({ isLoadingConsolidationManagement: false }));

            displaySuccess("Shipments consolidation change applied successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetRatesLoadingStates({ isLoadingConsolidationManagement: false }));
                displayError(e.response.data);
            }
        }
    };
};

export default ratesProductReducer;
